*{
  box-sizing: border-box;
  scroll-behavior: smooth;
  }
  .bgBlack{
    background-color: #141519;
    color: #fff;
  }
  .bgWhite{
    background-color: #fff;
    color: #141519;
  }
 .yellowColor{
  background-color:  #ffba00;

 }
 .text-theme{
  /* color:  #ffba00; */
color: #ffba00;
 }
 .greyBg{
  background-image: linear-gradient(180deg,#14151991,rgba(20,21,25,.86) 70%,#141519);
 } 
.text-grey{
  color: hsla(0,0%,100%,.75);
}
.headingimg {
  background: url('./Assets/cc1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%; 
}
.gazetImg{
  background: url('./Assets/gazet.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%; 

}
.vidbg{
  background: url("../src/Assets/Badminton.mp4");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%; 
}
.headingimgmob{

  background: url('./Assets/2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%; 
}
.text{
	/* text-align: center; */
	padding: 0;
	margin: 0;
	transform: translateX(-200%);
	opacity: 0;
	animation: slide-in-anim 1.5s ease-out forwards;
}
@keyframes slide-in-anim {
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}
.heading3{
  font-size: 30px;
  font-weight: bolder;
  }
  .heading1{
    font-size: 35px;
    font-weight: bold;
    line-height: 1.2;
   
    }
    .heading5{
      font-size: 25px;
      font-weight: bolder;
      }
      .heading6{
        font-size: 20px;
        font-weight: bolder;
        }
      .heading4{
        font-size: 44px;
        font-weight: bold;
        line-height: 1.2;
       
        }
        .headingBig{
          font-size: 40px;
          font-weight: bold;
        }
    .arrowBox {
      background-color: #ffba00;
    position: absolute;
    bottom: -2px;
    right: -1px;
    width: 94px;
    height: 55px;
    border-radius: 30px 0px 0px 0px;
  }
  .arrow{
    position: absolute;
    bottom: 14px;
    right: 33px;
    font-size: 22px;

  }
  .card:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    transition: all 0.3s ease-out;
  }
  .card:hover .arrow { right: 28px;
  }
  .card:hover .arrowBox {  bottom: -1px;
  }

  section#counter-stats {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  
  .stats {
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
  }
  
  .stats .fa {
    color: #ffba00;
    font-size: 60px;
  }
  .rotate:hover {
    rotate: 360deg;
    transition-duration: 1s;
  }

  .containerforimg {
    position: relative;
    width: 50%;
  }
  
  .imageoverlay {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
  }
  
  .containerforimg:hover .overlay {
    opacity: 1;
  }
  
  .text {
    padding: 0;
    margin: 0;
    transform: translateX(-200%);
    opacity: 0;
    animation: slide-in-anim 1.5s ease-out forwards;
  }
  
/* p{ text-align: justify;
  text-justify: inter-word;} */

.textJustify{
  text-align: justify;
  text-justify: inter-word;

}

  #scroll-container {
background-color: #141519;
color:red;
  border: 3px solid black;
  border-radius: 5px;
  overflow: hidden;
}
#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
 /* for rainbow division */
.wrapper { 
  /* height: 50px;
  width: 50px; */
  /* left:0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute; */
background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
background-size: 1800% 1800%;

-webkit-animation: rainbow 18s ease infinite;
-z-animation: rainbow 18s ease infinite;
-o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
 /* end rainbow division */

.headingimg2 {
  background: url('./Assets/slide3.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* height: 100%;  */
  
}
.custombtn{
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.cardWrapper{

  --card-gradient: rgba(0, 0, 0, 0.8);
  --card-gradient: #5e9ad9, #e271ad;
  --card-blend-mode: overlay;
  background-color: #fff;
  background-image: linear-gradient(
    var(--card-gradient),
    white max(9.5rem, 27vh)
  );

}
.cardImg{
  mix-blend-mode: var(--card-blend-mode);
}

/* .cardWrapper1{

  --card-gradient: rgba(0, 0, 0, 0.8);
  --card-gradient: #b4d0ee, #89a9db;
  --card-blend-mode: overlay;
  background-color:black;
  background-image: linear-gradient(
    var(--card-gradient),
    rgb(7, 7, 7) max( 35vh)
  ); 

}
.cardImg1{
  mix-blend-mode: var(--card-blend-mode);
} */

.bannerParent {
  position: relative;
  height: 800px;

}
.bannerimg {
  background-image: url('./Assets/slide1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  /* filter: blur(2px);
  -webkit-filter: blur(2px); */
  /* position: absolute; */
  height: 800px;
}
.bg-text {
  background-color: rgb(0, 0, 0);
 /* Fallback color */
 background-color: rgba(0, 0, 0, 0.4); 
 /* Black w/opacity/see-through */
 color: white;
 font-weight: bold;
 /* border: 3px solid #f1f1f1; */
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 2;
 width: 100%;
 padding: 70px;
 text-align: center;
 height: 800px;

}

nav.transparent-header {
  position: absolute;
  right: 0;
  left: 0;
  background-color: transparent !important;
}
nav.transparent-header .nav-link{
  /* color:  #ffba00 !important; */
  color:  white !important;

}
 .nav-link:hover {
  border-bottom:1px solid  #ffba00;

}
.bannerText{
  font-size: 18vh;
}
/* 
.custom-btn {
  background:#29abe2;
  color: white;
  border: 2px solid #29abe2;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
} */
.videoSlide{
  /* width: 320px;
  height: 240px; */
  width: 920px;
  height: 340px;
}
.videoSlide1{
  width: 720px;
  height: 440px;
}
.bullet{
  font-size: 15px;
}

/* for marquee */
.example1 {
  height: 50px;	
  overflow: hidden;
  position: relative;
 }
 .example1 .marquee {
  /* font-size: 3em; */
  /* color: limegreen; */
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  /* line-height: 50px; */
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%);	
  transform:translateX(100%);
  /* Apply animation to this element */	
  -moz-animation: example1 20s linear infinite;
  -webkit-animation: example1 20s linear infinite;
  animation: example1 20s linear infinite;
 }
 /* Move it (define the animation) */
 @-moz-keyframes example1 {
  0%   { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
 }
 @-webkit-keyframes example1 {
  0%   { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
 }
 @keyframes example1 {
  0%   { 
  -moz-transform: translateX(100%); /* Firefox bug fix */
  -webkit-transform: translateX(100%); /* Firefox bug fix */
  transform: translateX(100%); 		
  }
  100% { 
  -moz-transform: translateX(-100%); /* Firefox bug fix */
  -webkit-transform: translateX(-100%); /* Firefox bug fix */
  transform: translateX(-100%); 
  }
 }
.marqueefont{
  font-size: 20px;
}
.blueTheme{
  color: #2606fb;
}
#background-video{
  height: 100%;
  width: 100%;
  filter: brightness(30%);

  }
  #contentVid{
    position:absolute;
    bottom: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    background-size: cover;
    color: #f1f1f1;
    width: 100%;
    height: 100%;

    padding: 50px;

  }

.user-content p{
    margin-top:5px;
    font-size:12px;
}


.ratings i{
    color:#ffba00;
}
.bgUserform{
  background-image: url('./Assets/R.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  height: 600px;


}
  
  @media screen and (max-width: 768px) {
    .bgUserform{
      height: 500px;
    }
    #contentVid{
     
  
      padding-top: 80px;
  
    }
    .heading1{
      font-size: 14px;
      }
      .heading3{
        font-size: 10px;
        }
        .heading5{
          font-size: 8px;
          }
          .heading4{
            font-size: 12px;
            }
            .heading6{
              font-size: 8px;
              }
           #formob{
              padding: 2px;
    font-size: 11px;
    margin: 2px;
            }
            .bannerText{
              font-size: 8vh;
            }
            .headingBig{
              font-size: 14px;
            }
            .videoSlide{
              width: 260px;
              height: 150px;
            }
            .videoSlide1{
              width: 260px;
              height: 150px;
            }
            .bullet{
              font-size: 10px;
            }
            p{ text-align:start;
              text-justify: none;}
            
            .textJustify{
              text-align: start;
              text-justify: none;
            
            }
            .marqueefont{
              font-size: 12px;
            }
      }